import Api from '@/services/api'
import { each, find } from 'lodash'
import Invoice from '@/models/Invoice'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    invoiceDetails: null,
    invoiceCount: 0,
    tradieBankDetail: [],
  },

  mutations: {
    setInvoiceList(state, invoices) {
      each(invoices, (invoice) => {
        const exist = find(state.list, { id: invoice.id })
        if (!exist) {
          state.list.push(new Invoice(invoice))
        }
      })
    },

    clearInvoiceList(state) {
      state.list = []
    },

    setInvoiceListMeta(state, meta) {
      state.listMeta = meta
    },

    setInvoiceDetails(state, invoice) {
      if (invoice instanceof Invoice) {
        state.invoiceDetails = invoice
      } else {
        state.invoiceDetails = new Invoice(invoice)
      }
    },

    setInvoiceCount(state, invoiceCount) {
      state.invoiceCount = invoiceCount
    },

    setTradieBankDetail(state, tradieBankDetail) {
      state.tradieBankDetail = tradieBankDetail
    },

    deleteInvoice(state) {
      state.invoiceDetails = null
    },

    clearInvoiceDetails(state) {
      state.invoiceDetails = null
    },
  },

  getters: {
    invoiceCount(state) {
      return state.invoiceCount
    },
    tradieBankDetail(state) {
      return state.tradieBankDetail
    },
  },

  actions: {
    async getInvoices(
      { commit },
      { page = 1, search = '', sort = '', status = '', dateSpan = '' }
    ) {
      const query = Invoice.page(page)

      if (sort) {
        query.orderBy(sort)
      }

      if (search) {
        query.where('search', search)
      }

      if (status) {
        query.where('status', status)
      }

      if (dateSpan) {
        query.where('serviceDateBetween', dateSpan)
      }

      const res = await query.params({ limit: 50 }).get()

      commit('setInvoiceListMeta', res.meta)
      commit('setInvoiceList', res.data)
      commit('setInvoiceCount', res.meta.total)
    },

    async getInvoiceDetails({ commit, dispatch }, id) {
      const { data } = await Api.get(`invoices/${id}`)
      commit('setInvoiceDetails', data.data)
      dispatch('getTredieBankDetails', data.data.tradie_profile)
    },

    async getTredieBankDetails({ commit }, tradie) {
      const { data } = await Api.get(
        `/tradie/bank-account-detail/${tradie.user_id}`
      )
      commit('setTradieBankDetail', data.data)
    },

    async updateInvoice({ commit }, formData) {
      const { data } = await Api.put(
        `invoices/${formData.invoice_id}`,
        formData
      )
      commit('setInvoiceDetails', data.data)
    },

    async deleteInvoice({ commit }, formData) {
      await Api.delete(`invoices/${formData.invoice_id}`)
      commit('deleteInvoice')
    },

    async exportInvoice({ commit }, invoice) {
      return await Api.get(`/customer/invoice-export/${invoice}`, {
        responseType: 'blob',
      })
    },
  },
}
