import Tradie from '@/models/Admin/Tradie'
import { extend } from 'lodash'

export default {
  namespaced: true,

  state: {
    loadingNewTradie: true,
    tradie: {
      tradie_id: null,
      abn: '',
      business_contact_number: null,
      business_name: null,
      business_type: {},
      business_type_id: null,
      completed_at: null,
      description: null,
      hash_id: null,
      is_apply_to_gst: true,
      license_number: null,
      primary_contact_name: '',
      trade_license_id: null,
      user: {},
      user_id: null,
      website: null,
    },
  },

  mutations: {
    setNewTradie(state, tradie) {
      state.tradie = new Tradie(extend(state.tradie, tradie))
    },

    resetTradieDetails(state) {
      state.tradie = {
        tradie_id: null,
        abn: '',
        business_contact_number: null,
        business_name: null,
        business_type: {},
        business_type_id: null,
        completed_at: null,
        description: null,
        hash_id: null,
        is_apply_to_gst: true,
        license_number: null,
        primary_contact_name: '',
        trade_license_id: null,
        user: {},
        user_id: null,
        website: null,
      }
    },
  },

  actions: {
    async getTradieDetails({ commit, state }, tradieId) {
      state.loadingNewTradie = true

      const { data } = await Tradie.find(tradieId)

      commit('setNewTradie', data)
      state.loadingNewTradie = false
    },
  },
}
