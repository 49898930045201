import Vue from 'vue'
import Router from 'vue-router'
import auth from './auth'
import tradies from './modules/tradies'
import privacy from './privacy'
import terms from './terms'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    auth,
    privacy,
    terms,
    {
      path: '/',
      name: 'home',
      redirect: { name: 'customers' },
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
      children: [
        ...tradies,
        {
          path: 'inbox',
          name: 'inbox',
          component: () =>
            import(/* webpackChunkName: "inbox" */ '@/views/Home/Inbox'),
        },
        {
          path: 'users',
          name: 'users',
          component: () =>
            import(/* webpackChunkName: "users" */ '@/views/Home/Users'),
        },
        {
          path: 'users/:id',
          name: 'user.details',
          component: () =>
            import(
              /* webpackChunkName: "users" */ '@/views/Home/Users/UserDetails'
            ),
        },
        {
          path: 'customers',
          name: 'customers',
          component: () =>
            import(
              /* webpackChunkName: "customers" */ '@/views/Home/Customers'
            ),
        },
        {
          path: 'customers/:id',
          name: 'customer.details',
          component: () =>
            import(
              /* webpackChunkName: "customer-details" */ '@/views/Home/Customers/CustomerDetails'
            ),
        },
        {
          path: 'invoices',
          name: 'invoices',
          component: () =>
            import(/* webpackChunkName: "invoices" */ '@/views/Home/Invoices'),
        },
        {
          path: 'invoices/:id',
          name: 'invoice.details',
          component: () =>
            import(
              /* webpackChunkName: "invoice-details" */ '@/views/Home/Invoices/InvoiceDetails'
            ),
        },
        {
          path: 'payments',
          name: 'payments',
          component: () =>
            import(/* webpackChunkName: "payments" */ '@/views/Home/Payments'),
        },
        {
          path: 'payments/:id',
          name: 'payment.details',
          component: () =>
            import(
              /* webpackChunkName: "payment-details" */ '@/views/Home/Payments/PaymentDetails'
            ),
        },
        {
          path: 'content',
          name: 'content',
          component: () =>
            import(/* webpackChunkName: "content" */ '@/views/Home/Content'),
        },
        {
          path: 'push-notification',
          name: 'push-notification',
          component: () =>
            import(
              /* webpackChunkName: "push-notification" */ '@/views/Home/PushNotification'
            ),
        },
        {
          path: 'settings',
          name: 'settings',
          component: () =>
            import(/* webpackChunkName: "settings" */ '@/views/Home/Settings'),
        },
        {
          path: 'reports',
          name: 'reports',
          component: () =>
            import(/* webpackChunkName: "reports" */ '@/views/Home/Reports'),
        },
        {
          path: 'business-areas',
          name: 'business-areas',
          component: () =>
            import(
              /* webpackChunkName: "business-areas" */ '@/views/Home/BusinessAreas'
            ),
        },
        {
          path: 'business-areas/:id',
          name: 'business-area.details',
          component: () =>
            import(
              /* webpackChunkName: "business-area-details" */ '@/views/Home/BusinessAreas/BusinessAreaDetails'
            ),
        },
        {
          path: 'activity',
          name: 'activity',
          component: () =>
            import(/* webpackChunkName: "activity" */ '@/views/Home/Activity'),
        },
        {
          path: 'activity/:id',
          name: 'activity.details',
          component: () =>
            import(
              /* webpackChunkName: "activity-details" */ '@/views/Home/Activity/ActivityDetails'
            ),
        },
      ],
    },
  ],
})
