import Model from './Model'
import Avatar from './Avatar'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default class User extends Model {
  resource() {
    return 'users'
  }

  avatar() {
    return this.hasMany(Avatar)
  }

  get createdAtAttribute() {
    return dayjs.utc(this.created_at).format('MMM DD, YYYY hh:mm A')
  }

  get path() {
    return {
      name: 'user.details',
      params: {
        id: this.id,
      },
    }
  }
}
