import Model from './Model'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default class Payment extends Model {
  resource() {
    return 'payments'
  }

  get amountAttribute() {
    return this.moneyFormat(this.amount)
  }

  get zipChargeAttribute() {
    return this.moneyFormat(this.zip_charge)
  }

  get tradenowFeeAttribute() {
    return this.moneyFormat(this.tradenow_fee)
  }

  get payableAttribute() {
    return this.moneyFormat(this.payable)
  }

  get paidAtAttribute() {
    return dayjs.utc(this.paid_at).format('DD/M/YY')
  }

  get tradieIdAttribute() {
    return this.tradie_profile?.hash_id
  }

  get licenseNumberAttribute() {
    return this.tradie_profile?.license_number
  }

  get path() {
    return {
      name: 'payment.details',
      params: {
        id: this.payment_id,
      },
    }
  }
}
