import User from '@/models/User'
import Avatar from '@/models/Avatar'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    userDetails: null,
    userCount: 0,
  },

  mutations: {
    setUserList(state, users) {
      each(users, (user) => {
        const exist = find(state.list, { id: user.id })
        if (!exist) {
          state.list.push(new User(user))
        }
      })
    },

    clearUserList(state) {
      state.list = []
    },

    setUserListMeta(state, meta) {
      state.listMeta = meta
    },

    setUserDetails(state, user) {
      if (user instanceof User) {
        state.userDetails = user
      } else {
        state.userDetails = new User(user)
      }
    },

    setSelectedUserAvatar(state, avatar) {
      if (avatar instanceof Avatar) {
        state.userDetails.avatar = avatar
      } else {
        state.userDetails.avatar = new Avatar(avatar)
      }
    },

    setUserCount(state, userCount) {
      state.userCount = userCount
    },

    clearUserDetails(state) {
      state.userDetails = null
    },

    userAvatarRemoved(state) {
      state.userDetails.avatar = null
    },
  },

  getters: {
    userCount(state) {
      return state.userCount
    },
  },

  actions: {
    async getUsers(
      { commit },
      { page = 1, search = '', sort = '', type = '' }
    ) {
      const query = User.page(page)

      if (sort) {
        query.orderBy(sort)
      }

      if (search) {
        query.where('search', search)
      }

      if (type) {
        query.where('type', type)
      }

      const res = await query.params({ limit: 50 }).get()

      commit('setUserList', res.data)
      commit('setUserListMeta', res.meta)
      commit('setUserCount', res.meta.total)
    },

    async getUserDetails({ commit }, id) {
      const { data } = await Api.get(`users/${id}`)
      commit('setUserDetails', data.data)
    },

    async changeAvatar({ commit }, formData) {
      const { data } = await Api.post(
        `users/${formData.get('user_id')}/avatar`,
        formData
      )
      commit('setSelectedUserAvatar', data.data)
    },

    async updateUser({ commit }, formData) {
      const { data } = await Api.put(`users/${formData.id}`, formData)
      commit('setUserDetails', data.data)
    },

    async updateIncorrectEmail({ commit }, formData) {
      const { data } = await Api.put(
        `email-incorrect-update/${formData.id}`,
        formData
      )
      commit('setUserDetails', data.data)
    },

    async exportToExcel() {
      return await Api.get('/users/export', { responseType: 'blob' })
    },
  },
}
