import Model from './Model'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default class Invoice extends Model {
  resource() {
    return 'invoices'
  }

  get dateOfServiceAttribute() {
    return dayjs.utc(this.date_of_service).format('MMM DD, YYYY')
  }

  get nextPaymentDueAttribute() {
    return this.next_payment_due !== null
      ? dayjs.utc(this.next_payment_due).format('MMM DD, YYYY')
      : '---'
  }

  get totalAttribute() {
    return this.moneyFormat(this.total)
  }

  get serviceFeeAttribute() {
    return this.moneyFormat(this.service_fee)
  }

  get subtotalAttribute() {
    return this.moneyFormat(this.subtotal)
  }

  get gstFee() {
    return this.moneyFormat(this.subtotal * 0.1)
  }

  get totalWithGstAttribute() {
    return this.moneyFormat(this.total_with_gst)
  }

  get paidAttribute() {
    return this.moneyFormat(this.paid)
  }

  get remainingAttribute() {
    return this.moneyFormat(this.remaining)
  }

  get invoiceNumber() {
    return this.invoice_id.toString().padStart(8, '0')
  }

  get createdAtAttribute() {
    return dayjs.utc(this.created_at).format('MMM DD, YYYY')
  }

  get tradieIdAttribute() {
    return this.tradie_profile?.hash_id
  }

  get path() {
    return {
      name: 'invoice.details',
      params: {
        id: this.invoice_id,
      },
    }
  }
}
