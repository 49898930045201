import BusinessArea from '@/models/BusinessArea'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    businessAreaDetails: null,
    businessAreaCount: 0,
  },

  mutations: {
    setBusinessAreaList(state, businessAreas) {
      each(businessAreas, (businessArea) => {
        const exist = find(state.list, { id: businessArea.id })
        if (!exist) {
          state.list.push(new BusinessArea(businessArea))
        }
      })
    },

    clearBusinessAreaList(state) {
      state.list = []
    },

    setBusinessAreaListMeta(state, meta) {
      state.listMeta = meta
    },

    setBusinessAreaDetails(state, businessArea) {
      if (businessArea instanceof BusinessArea) {
        state.businessAreaDetails = businessArea
      } else {
        state.businessAreaDetails = new BusinessArea(businessArea)
      }
    },

    setBusinessAreaCount(state, businessAreaCount) {
      state.businessAreaCount = businessAreaCount
    },

    clearBusinessAreaDetails(state) {
      state.businessAreaDetails = null
    },
  },

  getters: {
    businessAreaCount(state) {
      return state.businessAreaCount
    },
  },

  actions: {
    async getBusinessAreas(
      { commit },
      { page = 1, search = '', sort = '', limit = 50 }
    ) {
      const query = BusinessArea.page(page)

      if (sort) {
        query.orderBy(sort)
      }

      if (search) {
        query.where('search', search)
      }

      const res = await query.params({ limit: limit }).get()

      commit('setBusinessAreaList', res.data)
      commit('setBusinessAreaCount', res.meta.total)
      commit('setBusinessAreaListMeta', res.meta)
    },

    async getBusinessAreaDetails({ commit }, id) {
      const { data } = await Api.get(`business-areas/${id}`)
      commit('setBusinessAreaDetails', data.data)
    },

    async updateBusinessArea({ commit }, formData) {
      const { data } = await Api.put(`business-areas/${formData.id}`, formData)
      commit('setBusinessAreaDetails', data.data)
    },

    async exportToExcel() {
      return await Api.get('/business-areas/export', { responseType: 'blob' })
    },
  },
}
