import { Model as BaseModel } from 'vue-api-query'

export default class AdminBaseModel extends BaseModel {
  baseURL() {
    return 'admin'
  }

  // Implement a default request method
  request(config) {
    return this.$http.request(config)
  }

  moneyFormat(value, currency = 'USD') {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    })

    return formatter.format(value)
  }
}
