import Model from './Model'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default class BusinessArea extends Model {
  resource() {
    return 'business-areas'
  }

  get createdAtAttribute() {
    return dayjs.utc(this.created_at).format('MMM DD, YYYY hh:mm A')
  }

  get path() {
    return {
      name: 'business-area.details',
      params: {
        id: this.id,
      },
    }
  }
}
