import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import customer from './modules/customer'
import tradie from './modules/tradie'
import invoice from './modules/invoice'
import payment from './modules/payment'
import businessArea from './modules/business-area'
import activity from './modules/activity'
import newTradie from './modules/new-tradie'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    user,
    customer,
    tradie,
    invoice,
    payment,
    businessArea,
    activity,
    newTradie,
  },

  state: {
    controls: {
      showDrawer: true,
    },
  },

  mutations: {
    toggleDrawer(state, value) {
      state.controls.showDrawer = value
    },
  },
})
