import Activity from '@/models/Activity'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    activityDetails: null,
  },

  mutations: {
    setActivityList(state, activities) {
      each(activities, (activity) => {
        const exist = find(state.list, { id: activity.id })
        if (!exist) {
          state.list.push(new Activity(activity))
        }
      })
    },

    clearActivityList(state) {
      state.list = []
    },

    setActivityListMeta(state, meta) {
      state.listMeta = meta
    },

    setActivityDetails(state, activity) {
      if (activity instanceof Activity) {
        state.activityDetails = activity
      } else {
        state.activityDetails = new Activity(activity)
      }
    },

    clearActivityDetails(state) {
      state.activity = null
    },
  },

  actions: {
    async getActivities({ commit }, { page = 1, search = '', sort = '' }) {
      const query = Activity.page(page)

      if (sort) {
        query.orderBy(sort)
      }

      if (search) {
        query.where('search', search)
      }

      const res = await query.params({ limit: 50 }).get()

      commit('setActivityList', res.data)
      commit('setActivityListMeta', res.meta)
    },

    async getActivityDetails({ commit }, id) {
      const { data } = await Api.get(`activity/${id}`)
      commit('setActivityDetails', data.data)
    },
  },
}
