import Model from './Model'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default class Activity extends Model {
  resource() {
    return 'activity'
  }

  get addedDate() {
    return dayjs.utc(this.created_at).format('MM/DD/YY, hh:mm A')
  }

  get createdAtAttribute() {
    return dayjs.utc(this.created_at).format('MMM DD, YYYY hh:mm A')
  }

  get emailAttribute() {
    return this.user?.email
  }

  get path() {
    return {
      name: 'activity.details',
      params: {
        id: this.id,
      },
    }
  }
}
