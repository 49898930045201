import Api from '@/services/api'
import { each, find } from 'lodash'
import Customer from '@/models/Customer'

export default {
  namespaced: true,
  state: {
    list: [],
    customerCount: 0,
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    customerDetails: null,
    addressItems: [],
  },

  mutations: {
    setCustomerList(state, customers) {
      each(customers, (customer) => {
        const exist = find(state.list, { id: customer.id })
        if (!exist) {
          state.list.push(new Customer(customer))
        }
      })
    },

    clearCustomerList(state) {
      state.list = []
    },

    setCustomerListMeta(state, meta) {
      state.listMeta = meta
    },

    setCustomerCount(state, customerCount) {
      state.customerCount = customerCount
    },

    setCustomerDetails(state, customer) {
      if (customer instanceof Customer) {
        state.customerDetails = customer
      } else {
        state.customerDetails = new Customer(customer)
      }
    },

    setAddressItems(state, addressItems) {
      state.addressItems = addressItems
    },

    deleteCustomer(state) {
      state.customerDetails = null
    },

    clearCustomerDetails(state) {
      state.customerDetails = null
    },
    clearAddressItem(state) {
      state.addressItems = []
    },
  },

  getters: {
    customerCount(state) {
      return state.customerCount
    },
    addressItems(state) {
      return state.addressItems
    },
  },

  actions: {
    async getCustomers({ commit }, { page = 1, search = '', sort = '' }) {
      const query = Customer.page(page)

      if (sort) {
        query.orderBy(sort)
      }

      if (search) {
        query.where('search', search)
      }

      const res = await query.params({ limit: 50 }).get()

      commit('setCustomerList', res.data)
      commit('setCustomerListMeta', res.meta)
      commit('setCustomerCount', res.meta.total)
    },

    async getCustomerDetails({ commit }, id) {
      const { data } = await Api.get(`customers/${id}`)
      commit('setCustomerDetails', data.data)
    },

    async updateCustomer({ commit }, formData) {
      const { data } = await Api.put(
        `customers/${formData.customer_id}`,
        formData
      )
      commit('setCustomerDetails', data.data)
      commit('clearAddressItem')
    },

    async updateGreenIdStatus({ commit }, formData) {
      formData.update_type = true

      const { data } = await Api.put(
        `customers/${formData.customer_id}`,
        formData
      )
      commit('setCustomerDetails', data.data)
    },

    async deleteCustomer({ commit }, formData) {
      await Api.delete(`customers/${formData.customer_id}`)
      commit('deleteCustomer')
    },

    async checkAddress({ commit }, formData) {
      const { data } = await Api.post(`/check-address`, formData)
      commit('setAddressItems', data.data)
    },
  },
}
