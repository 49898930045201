import Api from '@/services/api'
import { each, find } from 'lodash'
import Tradie from '@/models/Tradie'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    tradieDetails: null,
    tradieCount: 0,
    addressList: [],
  },

  mutations: {
    setTradieList(state, tradies) {
      each(tradies, (tradie) => {
        const exist = find(state.list, { id: tradie.id })
        if (!exist) {
          state.list.push(new Tradie(tradie))
        }
      })
    },

    clearTradieList(state) {
      state.list = []
    },

    setTradieListMeta(state, meta) {
      state.listMeta = meta
    },

    setTradieDetails(state, tradie) {
      if (tradie instanceof Tradie) {
        state.tradieDetails = tradie
      } else {
        state.tradieDetails = new Tradie(tradie)
      }
    },

    setAddressItems(state, addressList) {
      state.addressList = addressList
    },

    setTradieCount(state, tradieCount) {
      state.tradieCount = tradieCount
    },

    deleteTradie(state) {
      state.tradieDetails = null
    },

    clearTradieDetails(state) {
      state.tradieDetails = null
    },

    clearAddressItem(state) {
      state.addressList = []
    },
  },

  getters: {
    tradieCount(state) {
      return state.tradieCount
    },
    addressList(state) {
      return state.addressList
    },
  },

  actions: {
    async getTradies(
      { commit },
      { page = 1, search = '', sort = '', draft = false }
    ) {
      const query = Tradie.page(page)

      if (sort) {
        query.orderBy(sort)
      }

      if (search) {
        query.where('search', search)
      }

      if (draft) {
        query.where('draft', draft)
      }

      const res = await query.params({ limit: 50 }).get()

      commit('setTradieList', res.data)
      commit('setTradieListMeta', res.meta)
      commit('setTradieCount', res.meta.total)
    },

    async getTradieDetails({ commit }, id) {
      const { data } = await Api.get(`tradies/${id}`)
      commit('setTradieDetails', data.data)
    },

    async updateTradie({ commit }, formData) {
      const { data } = await Api.put(`tradies/${formData.tradie_id}`, formData)
      commit('setTradieDetails', data.data)
      commit('clearAddressItem')
    },

    async deleteTradie({ commit }, tradieUser) {
      await this.$api.delete(`/admin/tradies/${tradieUser}/delete-account`)
    },

    async checkAddress({ commit }, formData) {
      const { data } = await Api.post(`/check-address`, formData)
      commit('setAddressItems', data.data)
    },
  },
}
