import Api from '@/services/api'
import { each, find } from 'lodash'
import Payment from '@/models/Payment'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    paymentDetails: null,
    paymentCount: 0,
  },

  mutations: {
    setPaymentList(state, payments) {
      each(payments, (payment) => {
        const exist = find(state.list, { id: payment.id })
        if (!exist) {
          state.list.push(new Payment(payment))
        }
      })
    },

    clearPaymentList(state) {
      state.list = []
    },

    setPaymentListMeta(state, meta) {
      state.listMeta = meta
    },

    setPaymentDetails(state, payment) {
      if (payment instanceof Payment) {
        state.paymentDetails = payment
      } else {
        state.paymentDetails = new Payment(payment)
      }
    },

    setPaymentCount(state, paymentCount) {
      state.paymentCount = paymentCount
    },

    deletePayment(state) {
      state.paymentDetails = null
    },

    clearPaymentDetails(state) {
      state.paymentDetails = null
    },
  },

  getters: {
    paymentCount(state) {
      return state.paymentCount
    },
  },

  actions: {
    async getPayments(
      { commit },
      { page = 1, search = '', sort = '', dateSpan = '', paymentStatus = '' }
    ) {
      const query = Payment.page(page)

      if (sort) {
        query.orderBy(sort)
      }

      if (search) {
        query.where('search', search)
      }

      if (dateSpan) {
        query.where('paidAt', dateSpan)
      }

      if (paymentStatus) {
        query.where('paymentStatus', paymentStatus)
      }

      const res = await query.params({ limit: 50 }).get()

      commit('setPaymentListMeta', res.meta)
      commit('setPaymentCount', res.meta.total)
      commit('setPaymentList', res.data)
    },

    async getPaymentDetails({ commit }, id) {
      const { data } = await Api.get(`payments/${id}`)
      commit('setPaymentDetails', data.data)
    },

    async updatePaymentStatus({ commit }, { id, status }) {
      const { data } = await Api.put(`payments/${id}`, {
        status: status,
      })
      commit('setPaymentDetails', data.data)
    },

    async deletePayment({ commit }, formData) {
      await Api.delete(`payments/${formData.payment_id}`)
      commit('deletePayment')
    },

    async exportToExcel() {
      return await Api.get('/payments/export', { responseType: 'blob' })
    },
  },
}
