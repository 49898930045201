export default [
  {
    path: 'tradies',
    name: 'tradies',
    component: () =>
      import(/* webpackChunkName: "tradies" */ '@/views/Home/Tradies'),
  },

  {
    path: 'tradies/new/:id?',
    name: 'new-tradie',
    redirect: { name: 'new-tradie-certification' },
    component: () =>
      import(
        /* webpackChunkName: "new-tradie" */ '@/views/Home/Tradies/NewTradie'
      ),
    children: [
      {
        path: 'business-certification',
        name: 'new-tradie-certification',
        props: true,
        meta: {
          newTradie: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "new-tradie-certification" */ '@/views/Home/Tradies/NewTradie/Certification'
          ),
      },
      {
        path: 'tradie-details',
        name: 'new-tradie-details',
        props: true,
        meta: {
          newTradie: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "new-tradie-details" */ '@/views/Home/Tradies/NewTradie/TradieDetails'
          ),
      },
      {
        path: 'business-details',
        name: 'new-business-details',
        props: true,
        meta: {
          newTradie: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "new-business-details" */ '@/views/Home/Tradies/NewTradie/BusinessDetails'
          ),
      },
      {
        path: 'overview',
        name: 'new-tradie-overview',
        meta: {
          newTradie: true,
        },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "new-tradie-overview" */ '@/views/Home/Tradies/NewTradie/Overview'
          ),
      },
    ],
  },

  {
    path: 'tradies/:id',
    name: 'tradie.details',
    component: () =>
      import(
        /* webpackChunkName: "tradie-details" */ '@/views/Home/Tradies/TradieDetails'
      ),
  },
]
