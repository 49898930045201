import Model from './Model'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { isEmpty } from 'lodash'

dayjs.extend(utc)

export default class Tradie extends Model {
  resource() {
    return 'tradies'
  }

  get addedDate() {
    return dayjs.utc(this.created_at).format('MMM DD, YYYY hh:mm A')
  }

  get isCompleted() {
    return !isEmpty(this.completed_at)
  }

  get path() {
    return {
      name: this.isCompleted ? 'tradie.details' : 'new-tradie-certification',
      params: {
        id: this.tradie_id,
      },
    }
  }
}
