import Vue from 'vue'
import moment from 'moment'
import { isNumber } from 'lodash'

Vue.filter('formatDate', function (value, format = 'lll') {
  if (!value) return

  let datetime = String(value)

  if (isNumber(value)) {
    datetime = new Date(value)
  }

  return moment(datetime).format(format)
})
